@import './styles/reset.css';
@import './styles/variables.css';

html {
  font-size: 62.5%;
}

body {
  font-family: sans-serif;
  line-height: 1.6;
  font-size: 1.6rem;
  background: linear-gradient(#141e30, #243b55);
}
