a {
  text-decoration: none;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-menu a {
  font-size: 1.6rem;
}
